<template>
  <div class="terms-and-conditions mt-24 font-sans">
    <div class="w-full flex relative h-[340px] bg-[#eae3d9]">
      <div
        class="font-black leading-none max-lg:text-4xl lg:text-5xl text-center m-auto font-lora"
      >
        Terms and Conditions
      </div>
    </div>
    <div class="w-full max-w-rifyo mx-auto flex flex-col relative min-h-screen">
      <div class="relative max-xl:px-7">
        <div class="w-full lg:w-7/12 mx-auto py-8">
          <div class="w-full overflow-hidden prose">
            <p>
              I agree to provide valid personal data information, the
              confidentiality of the personal data that has been given will be
              protected in accordance with the privacy policy rules.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/footer';

  export default {
    components: {
      Footer,
    },

    computed: {
      url() {
        return window.location.href ? window.location.href : null;
      },
    },

    methods: {
      copy() {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied.');
      },
      async share() {
        try {
          await navigator.share({
            title: 'Terms & Conditions',
            url: window.location.href,
          });
        } catch (err) {
          alert(
            "Your browser doesn't support this share function. Try again on another browser."
          );
        }
      },
    },
  };
</script>

<style scoped>
  .prose {
    color: #1f252c !important;
  }
</style>
